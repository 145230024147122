import webApi from "./api"

export class PhotoUploadService {
  async uploadImage(formData, id, onProgressCallback = null) {
    try {
      const { data } = await webApi.post('/car-detail/image', formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        ...(onProgressCallback ? { onUploadProgress: onProgressCallback } : {}),
      });
      return ({
        ...data,
        id,
        onUpload: false
      })
    } catch (e) {
      return ({
        id,
        onUpload: false,
        err: true,
        url: '',
        ...(`${e}`.includes('Network Error') ? { networkError: true } : {}),
      })
    }
  }
  async deteleImg(filename) {
    try {
      await webApi.delete(`/car-detail/image/${filename}`);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async rotate(img) {
    try {
      const { data } = await webApi.patch(`car-detail/image/rotate/${img.filename}`)
      return data;
    } catch (err) {
      return { err: true };
    }
  }
  async detectPredominantColors(formData) {
    try {
      const { data } = await webApi.post('/car-detail/image-detecting/predominant', formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
      });
      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
}

const photoUploadService = new PhotoUploadService()

export default photoUploadService
